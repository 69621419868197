import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import claimActions from '../claim/actions'
import request from '../../request/axios/agency'
import middleware from '../../middleware/responseChecker'

export function * getAgentPolicyDetails () {
  yield takeEvery(actions.AGENT_POLICY_DETAILS_REQUEST, function * (payload) {
    let res = yield call(request.getAgentPolicyDetails, payload)
    if (res.status === 200) {
      yield put({
        type: actions.AGENT_POLICY_DETAILS_SUCCESS,
        data: JSON.parse(res.data),
        getAgentPolicyDetailResult: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.AGENT_POLICY_DETAILS_FAILURE,
        getAgentPolicyDetailResult: 'Gagal untuk mengambil detail policy'
      })
    }
  })
}

export function * validateAgencyClaim () {
  yield takeEvery(actions.VALIDATE_AGENCY_CLAIM, function * ({ payload }) {
    try {
      const query = `benefit_id=${payload.benefit_id}&internal_id=${payload.internal_id}`
      let res = yield call(request.validateAgentClaim, { query })
      if (res.status === 200) {
        yield put({
          type: claimActions.VALIDATE_CLAIM_STATUS,
          dataValidate: res.data,
          validateClaimStatus: 'success'
        })
      } else {
        middleware(res)
        yield put({
          type: claimActions.VALIDATE_CLAIM_STATUS,
          dataValidate: {},
          validateClaimStatus: res.data.error || 'message',
          validateClaimStatusCode: res.data.code
        })
      }
    } catch (e) {
      yield put({
        type: claimActions.VALIDATE_CLAIM_STATUS,
        dataValidate: {},
        validateClaimStatus: '',
        validateClaimStatusCode: ''
      })
    }
  })
}

export function * postAgencyClaimRequest () {
  yield takeEvery(actions.CREATE_AGENCY_CLAIM, function * (payload) {
    let res = yield call(request.createAgencyClaim, payload)
    if (res.status === 201) {
      const { claim_id: claimId } = res.data
      yield put({
        type: claimActions.SUCCESS_CLAIM,
        claimId,
        createClaimStatus: 'success'
      })
    } else {
      middleware(res)
      const { data } = res.response
      yield put({
        type: claimActions.FAILED_CLAIM,
        createClaimStatus: data.error
      })
    }
  })
}

export function * getAgencyBenefitsRequest () {
  yield takeEvery(actions.GET_AGENCY_BENEFITS, function * (payload) {
    try {
      yield put({
        type: actions.CLEAR_BENEFIT_LIST
      })
      yield put({
        type: claimActions.CLEAR_AGENCY_BENEFIT_LIST
      })
      let res = yield call(request.getAgencyBenefits, payload)
      if (res.status === 200) {
        const { data } = res
        yield put({
          type: actions.AGENCY_BENEFIT_LISTS,
          agencyBenefitList: data,
          successAgencyBenefits: 'success'
        })
      } else {
        middleware(res)
        yield put({
          type: actions.FAILED_GET_AGENCY_BENEFITS,
          failedGetBenefitLists: 'Maaf, kami tidak dapat menampilkan benefit anda'
        })
      }
    } catch (e) {
      yield put({
        type: actions.FAILED_GET_AGENCY_BENEFITS,
        failedGetBenefitLists: 'Maaf, kami tidak dapat menampilkan benefit anda'
      })
    }
  })
}

export function * getAgencyBenefitsLahatRequest () {
  yield takeEvery(actions.GET_AGENCY_BENEFITS, function * ({ payload }) {
    const { history } = payload
    yield put({
      type: actions.CLEAR_BENEFIT_LIST
    })
    yield put({
      type: claimActions.CLEAR_AGENCY_BENEFIT_LIST
    })
    let res = yield call(request.getAgencyBenefitLahat, payload)
    if (res.status === 200) {
      const { data } = res
      if (data.benefit_list_data?.length === 0) {
        yield put({
          type: actions.FAILED_GET_AGENCY_BENEFITS,
          message: 'Maaf, kami tidak dapat menampilkan benefit anda'
        })
      } else {
        yield put({
          type: actions.AGENCY_CLAIM_LIST_SUCCESS,
          data,
          message: 'success'
        })
      }
    } else {
      middleware(res, history)
      yield put({
        type: actions.FAILED_GET_AGENCY_BENEFITS,
        message: 'Maaf, kami tidak dapat menampilkan benefit anda'
      })
    }
  })
}

export function * getAgencyPolicyList () {
  yield takeEvery(actions.AGENCY_POLICY_LIST, function * (payload) {
    yield put({
      type: actions.AGENCY_POLICY_LIST_START,
      successAgencyPolicy: ''
    })
    yield put({
      type: actions.AGENCY_POLICY_LIST_REQUEST,
      successAgencyPolicy: ''
    })

    let res = yield call(request.agencyPolicyList, payload)
    if (res.status === 200) {
      const { data } = res
      const parsedData = JSON.parse(data)
      yield put({
        type: actions.AGENCY_POLICY_LIST_SUCCESS,
        agencyPolicyList: parsedData,
        metadata: parsedData?.meta_data,
        successAgencyPolicy: 'success',
        page: payload?.payload?.query?.page
      })
    } else {
      middleware(res)
      yield put({
        type: actions.AGENCY_POLICY_LIST_FAILURE,
        successAgencyPolicy: false
      })
    }
  })
}

export function * getAgencyClaimList () {
  yield takeEvery(actions.AGENCY_CLAIM_LIST, function * ({ payload = {} }) {
    yield put({
      type: actions.AGENCY_CLAIM_LIST_REQUEST
    })

    const { query = {} } = payload
    let partnerQuery = { ...query }

    let res = yield call(request.agencyClaimList, { query: partnerQuery })
    // console.log("response", res)
    // console.log("query", partnerQuery)
    // console.log("payload", payload)
    if (res.status === 200) {
      const { data } = res
      // console.log("agency data",data)
      yield put({
        type: actions.AGENCY_CLAIM_LIST_SUCCESS,
        agencyClaimList: data.data,
        metadata: data.meta_data,
        successAgencyClaimList: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.AGENCY_CLAIM_LIST_FAILURE,
        successAgencyClaimList: false
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getAgentPolicyDetails),
    fork(validateAgencyClaim),
    fork(postAgencyClaimRequest),
    fork(getAgencyBenefitsRequest),
    fork(getAgencyBenefitsLahatRequest),
    fork(getAgencyPolicyList),
    fork(getAgencyClaimList)
  ])
}
