import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects'
import actions from './actions'
import request from '../../request/axios/policy'
import middleware from '../../middleware/responseChecker'

export function * getPolicyList () {
  yield takeEvery(actions.POLICY_LIST, function * (data) {
    const {
      query = {},
      payload = {}
    } = data
    const { coverage = '', offset, limit } = payload
    const {
      policyState = 'COMPLETED,PENDING_DOCUMENT'
    } = query

    let partnerQuery = Object.assign({ offset, limit, policyState, coverage }, query)

    const appPartner = yield select((state) => state.App.partner)
    if (!!appPartner && appPartner !== 'pasarpolisa') {
      partnerQuery.partner = appPartner
    }
    yield put({
      type: actions.POLICY_LIST_START,
      getPolicyListMessage: ''
    })
    yield put({
      type: actions.POLICY_LIST_REQUEST,
      getPolicyListMessage: ''
    })
    let res = yield call(request.getPolicyList, { query: partnerQuery })
    if (res.status === 200) {
      yield put({
        type: actions.POLICY_LIST_RESULT,
        data: res.data.data,
        metadata: res.data.metadata,
        getPolicyListMessage: 'success'
      })
      yield put({
        type: actions.POLICY_LIST_SUCCESS
      })
    } else {
      middleware(res)
      yield put({
        type: actions.FAILED_GET_POLICY_LIST,
        getPolicyListMessage: 'Gagal untuk mengambil policy list'
      })
      yield put({
        type: actions.POLICY_LIST_FAILURE
      })
    }
  })
}
export function * getCLaimList () {
  yield takeEvery(actions.CLAIM_LIST, function * ({ payload = {} }) {
    yield put({
      type: actions.GET_CLAIM_LIST_REQUEST
    })

    const { query = {} } = payload
    let partnerQuery = { ...query }

    const appPartner = yield select((state) => state.App.partner)
    if (appPartner && appPartner !== 'pasarpolis') {
      partnerQuery.partner_id = appPartner
    }

    let res = yield call(request.getClaimList, { query: partnerQuery })

    if (res.status === 200) {
      yield put({
        type: actions.CLAIM_LIST_RESULT,
        data: res.data.data,
        metadata: res.data.meta_data,
        getClaimListMessage: 'success'
      })
      yield put({
        type: actions.GET_CLAIM_LIST_SUCCESS
      })
    } else {
      middleware(res)
      yield put({
        type: actions.GET_CLAIM_LIST_FAILURE
      })
      yield put({
        type: actions.FAILED_GET_CLAIM_LIST,
        getClaimListMessage: 'Gagal untuk mengambil claim list'
      })
    }
  })
}

export function * getPolicyClaimsList () {
  yield takeEvery(actions.POLICY_CLAIMS_LIST, function * ({ payload }) {
    const { query } = payload
    let partnerQuery = Object.assign({}, query)

    const appPartner = yield select((state) => state.App.partner)
    if (!!appPartner && appPartner !== 'pasarpolis') {
      partnerQuery.partner_id = appPartner
    }

    let res = yield call(request.getClaimList, { query: partnerQuery })
    if (res.status === 200) {
      yield put({
        type: actions.POLICY_CLAIM_LIST_RESULT,
        data: res.data.data,
        getPolicyClaimListMessage: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.FAILED_GET_POLICY_CLAIMS_LIST,
        getPolicyClaimListMessage: 'Gagal untuk mengambil policy claims list'
      })
    }
  })
}

export function * getPolicyDetails () {
  yield takeEvery(actions.POLICY_DETAILS_REQUEST, function * (payload) {
    let res = yield call(request.getPolicyDetails, payload)
    if (res.status === 200) {
      yield put({
        type: actions.POLICY_DETAILS_SUCCESS,
        data: res.data,
        getPolicyDetailResult: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.FAILED_GET_POLICY_DETAIL,
        getPolicyDetailResult: 'Gagal untuk mengambil detail policy'
      })
    }
  })
}

export function * getAggregatePolicyDetails () {
  yield takeEvery(actions.AGGREGATE_POLICY_DETAILS_REQUEST, function * (payload) {
    let res = yield call(request.getAggregatePolicyDetails, payload)
    if (res.status === 200) {
      yield put({
        type: actions.AGGREGATE_POLICY_DETAILS_SUCCESS,
        data: res.data,
        getPolicyDetailResult: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.AGGREGATE_POLICY_DETAILS_FAILURE,
        getPolicyDetailResult: 'Gagal untuk mengambil detail policy'
      })
    }
  })
}

export function * tokenisedEndorsementDetails () {
  yield takeEvery(actions.TOKENIZED_ENDORSEMENT_DETAIL_REQUEST, function * ({ payload }) {
    const { query } = payload
    let partnerQuery = Object.assign({}, query)

    let res = yield call(request.tokenisedEndorsementDetail, { query: partnerQuery })
    if (res.status === 200) {
      yield put({
        type: actions.TOKENIZED_ENDORSEMENT_DETAIL_SUCCESS,
        data: res.data,
        getPolicyDetailResult: 'success'
      })
    } else {
      middleware(res)
      yield put({
        type: actions.TOKENIZED_ENDORSEMENT_DETAIL_FAILURE,
        getPolicyDetailResult: 'Gagal untuk mengambil detail policy'
      })
    }
  })
}

export function * tokenisedEndorsement () {
  yield takeEvery(actions.TOKENIZED_ENDORSEMENT_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.tokenisedEndorsement, payload)
      if (res.status === 200) {
        yield put({
          type: actions.TOKENIZED_ENDORSEMENT_SUCCESS,
          data: res.data,
          getPolicyEndorsementResult: 'success'
        })
      } else {
        yield put({
          type: actions.TOKENIZED_ENDORSEMENT_FAILURE,
          data: res.data,
          getPolicyEndorsementResult: 'Gagal untuk mengambil detail policy'
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.TOKENIZED_ENDORSEMENT_FAILURE,
          message: e.data.error
        })
      }
    }
  })
}

export function * authorizedEndorsement () {
  yield takeEvery(actions.AUTHORIZED_ENDORSEMENT_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.authorizedEndorsement, payload)
      if (res.status === 200) {
        yield put({
          type: actions.AUTHORIZED_ENDORSEMENT_SUCCESS,
          data: res.data,
          getPolicyEndorsementResult: 'success'
        })
      } else {
        yield put({
          type: actions.AUTHORIZED_ENDORSEMENT_FAILURE,
          data: res.data,
          getPolicyEndorsementResult: 'Gagal untuk mengambil detail policy'
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.AUTHORIZED_ENDORSEMENT_FAILURE,
          message: e.data.error
        })
      }
    }
  })
}

export function * unAuthorizedEndorsement () {
  yield takeEvery(actions.UNAUTHORIZED_ENDORSEMENT_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.unAuthorizedEndorsement, payload)
      if (res.status === 200) {
        yield put({
          type: actions.UNAUTHORIZED_ENDORSEMENT_SUCCESS,
          data: res.data,
          getPolicyEndorsementResult: 'success'
        })
      } else {
        yield put({
          type: actions.UNAUTHORIZED_ENDORSEMENT_FAILURE,
          data: res.data,
          getPolicyEndorsementResult: 'Gagal untuk mengambil detail policy'
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.UNAUTHORIZED_ENDORSEMENT_FAILURE,
          message: e.data.error
        })
      }
    }
  })
}

export function * generateOtp () {
  yield takeEvery(actions.SEND_VERIFICATION_OTP_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.generateOtp, payload)
      if (res.status === 200) {
        yield put({
          type: actions.SEND_VERIFICATION_OTP_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SEND_VERIFICATION_OTP_FAILURE,
          data: res.data,
          message: 'error'
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.SEND_VERIFICATION_OTP_FAILURE,
          data: e.data.error,
          message: 'error'
        })
      }
    }
  })
}

export function * verifyOtp () {
  yield takeEvery(actions.VERIFY_VERIFICATION_OTP_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.verifyOtp, payload)
      if (res.status === 200) {
        yield put({
          type: actions.VERIFY_VERIFICATION_OTP_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.VERIFY_VERIFICATION_OTP_FAILURE,
          data: res.data,
          message: 'error'
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.VERIFY_VERIFICATION_OTP_FAILURE,
          data: e.data.error,
          message: 'error'
        })
      }
    }
  })
}

export function * resendOtp () {
  yield takeEvery(actions.RESEND_VERIFICATION_OTP_REQUEST, function * ({ payload }) {
    try {
      let res = yield call(request.resendOtp, payload)
      if (res.status === 200) {
        yield put({
          type: actions.RESEND_VERIFICATION_OTP_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.RESEND_VERIFICATION_OTP_FAILURE,
          data: res.data,
          message: 'error'
        })
      }
    } catch (e) {
      if (e) {
        yield put({
          type: actions.RESEND_VERIFICATION_OTP_FAILURE,
          data: e.data.error,
          message: 'error'
        })
      }
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getPolicyList),
    fork(getPolicyDetails),
    fork(getAggregatePolicyDetails),
    fork(getCLaimList),
    fork(getPolicyClaimsList),
    fork(tokenisedEndorsementDetails),
    fork(tokenisedEndorsement),
    fork(authorizedEndorsement),
    fork(unAuthorizedEndorsement),
    fork(generateOtp),
    fork(verifyOtp),
    fork(resendOtp)
  ])
}
